import * as frontChat from '@anm/helpers/frontChat';
import * as intercom from '@anm/helpers/intercom';
import isInIframe from '@anm/helpers/is/isInIframe';
import * as semrush from '@anm/helpers/semrush';
import { useEffect } from 'react';
import { AnimatronProduct } from 'types/global';
import { User } from 'user';

const getCanLoadIntercom = () => !isInIframe() && !semrush.isSemrushIframe();
const chat = () => (process.env.TS_ENV === 'prod' ? intercom : frontChat);

let initedBy: null | 'anonymous' | string = null;
const updateChat = (profile: User | null, hide_default_launcher = false, product: AnimatronProduct) => {
  const { setUser, setAnonymous } = chat();

  if (profile) {
    if (initedBy !== profile.userId) {
      setUser(profile, hide_default_launcher, product);
      initedBy = profile.userId;
    }
  } else if (initedBy !== 'anonymous') {
    setAnonymous({ hide_default_launcher });
    initedBy = 'anonymous';
  }
};

type UseIntercomProps = {
  id: string;
  user: User | null;
  enable: boolean;
  hide_default_launcher?: boolean;
  product: AnimatronProduct;
};

const useIntercom = ({ id, user, enable, hide_default_launcher, product }: UseIntercomProps) => {
  const canLoadIntercom = enable && getCanLoadIntercom();

  useEffect(() => {
    if (!canLoadIntercom) return;

    chat().load();
    chat().init(id);
  }, [canLoadIntercom]);

  useEffect(() => {
    if (!canLoadIntercom) return;

    updateChat(user, hide_default_launcher, product);
  }, [canLoadIntercom, user?.userId]);
};

export default useIntercom;
