import { AnimatronProduct } from 'types/global';
import { User } from 'user';
import getStudioSubscription from '../subscription/getStudioSubscription';
import getWaveSubscription from '../subscription/getWaveSubscription';

import waitFor from '../waitFor';

declare global {
  interface Window {
    FrontChat: any;
  }
}

let frontChatId: string;
export let isInit = false;

export const init = (id: string) => (frontChatId = id);

export const load = () => {
  function append() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.defer = true;
    s.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';

    setTimeout(() => document.body.appendChild(s), 1500);
  }
  if (document.readyState === 'complete') {
    append();
    return;
  }
  window.addEventListener('load', append, false);
};

export const frontChat = async (...a: any[]) => {
  await waitFor(() => !!window.FrontChat, 50, 'wait for FrontChat loaded');
  window.FrontChat && window.FrontChat(...a);
};

type BootData = {
  hide_default_launcher: boolean;
  userData?: {};
};

const boot = async (data = {} as BootData) => {
  if (!frontChatId) throw new Error('FrontChat: no id provided');

  const { hide_default_launcher, userData } = data;

  frontChat('init', {
    chatId: frontChatId,
    useDefaultLauncher: !hide_default_launcher,
    ...userData
  });

  isInit = true;
};

export const setUser = (
  { userId, username, email, meta: { frontStudioHash, frontWaveHash }, subscriptionDetails }: User,
  hide_default_launcher = false,
  product: AnimatronProduct = 'WAVE'
) => {
  userId &&
    boot({
      hide_default_launcher,
      userData: {
        name: username,
        userHash: product === 'WAVE' ? frontWaveHash : frontStudioHash,
        userId: userId,
        contact: {
          email: email,
          customFields: {
            Admin: `https://webadmin.animatron${
              process.env.TS_ENV === 'prod' ? '' : '-test'
            }.com/admin/user.html?userId=${userId}`,
            'Studio Plan': getStudioSubscription(subscriptionDetails)?.name,
            'Wave Plan': getWaveSubscription(subscriptionDetails)?.name
          }
        }
      }
    });
};

export const setAnonymous = (props: BootData) => {
  frontChat('shutdown');
  boot(props);
};

export const openChat = () => {
  frontChat('show');
};
